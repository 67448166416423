var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{attrs:{"fluid":""}},[_c('v-tabs',{model:{value:(_vm.selectedTab),callback:function ($$v) {_vm.selectedTab=$$v},expression:"selectedTab"}},[_c('v-tab',[_vm._v(" Нийтлэг мэдээлэл ")]),_c('v-tab',[_vm._v(" Судлагдахууны жагсаалт ")]),_c('v-tab',[_vm._v(" Дүнгийн сxем ")]),_c('v-tab',[_vm._v(" Ээлжит xичээлийн жагсаалт ")]),_c('v-tab',[_vm._v(" Teacher ")])],1),_c('v-tabs-items',{model:{value:(_vm.selectedTab),callback:function ($$v) {_vm.selectedTab=$$v},expression:"selectedTab"}},[_c('v-tab-item',[_c('h3',{staticClass:"mt-10 mx-4"},[_vm._v("Нийтлэг мэдээлэл татаx")]),(_vm.loading)?_c('v-progress-linear',{staticClass:"mx-4 mt-4",attrs:{"color":"red","height":"6","indeterminate":""}}):_vm._e(),_c('v-row',{staticClass:"px-4 my-4"},[_c('v-col',[_c('v-btn',{staticClass:"blue--text",attrs:{"elevation":"0"},on:{"click":function($event){return _vm._lookup()}}},[_vm._v("Нийтлэг мэдээлэл татаx")])],1),_c('v-col',[_c('v-select',{attrs:{"items":['EDUCATION_LEVEL', 'ACADEMIC_SHIFT', 'ACADEMIC_LOAD']},model:{value:(_vm.selectedLookup),callback:function ($$v) {_vm.selectedLookup=$$v},expression:"selectedLookup"}})],1)],1),(_vm.lookups)?_c('v-data-table',{attrs:{"headers":[
          { value: 'index', text: 'No' },
          { value: 'NAME', text: 'NAME' },
          { value: 'LOOKUP_VALUE', text: 'LOOKUP_VALUE' },
          { value: 'LOOKUP_TYPE', text: 'LOOKUP_TYPE' },

          { value: 'DESCRIPTION', text: 'DESCRIPTION' },

          { value: 'START_DATE', text: 'START_DATE' },
        ],"items":_vm.lookups,"item-value":"id","dense":"","search":_vm.search,"hide-default-footer":"","items-per-page":-1}}):_vm._e()],1),_c('v-tab-item',[_c('h3',{staticClass:"mt-10 mx-4"},[_vm._v("Судлагдахууны жагсаалт")]),(_vm.loading)?_c('v-progress-linear',{attrs:{"color":"red","height":"6","indeterminate":""}}):_vm._e(),_c('v-btn',{staticClass:"my-10 mx-4 blue--text",attrs:{"elevation":"0"},on:{"click":function($event){return _vm._callAPI('lookup')}}},[_vm._v("Судлагдахуун Татаx")]),(_vm.subjectareas)?_c('v-data-table',{attrs:{"headers":[
          { value: 'index', text: 'No' },
          { value: 'subjectName', text: 'SUBJECT_AREA_ID' },
          { value: 'subjectAreaId', text: 'subjectAreaId' },
        ],"items":_vm.subjectareas,"item-value":"id","dense":"","search":_vm.search,"hide-default-footer":"","items-per-page":-1}}):_vm._e()],1),_c('v-tab-item',[_c('h3',{staticClass:"mt-10 mx-4"},[_vm._v("Xичээлийн дүнгийн мэдээлэл")]),(_vm.loading)?_c('v-progress-linear',{attrs:{"color":"red","height":"6","indeterminate":""}}):_vm._e(),_c('v-row',{staticClass:"px-4 my-4"},[_c('v-col',[_c('v-btn',{staticClass:"my-10 mx-4 blue--text",attrs:{"elevation":"0"},on:{"click":function($event){return _vm._scoreScheme('lookup')}}},[_vm._v(" Татаx")])],1),_c('v-col',[_c('v-select',{attrs:{"items":[13, 15]},model:{value:(_vm.selectedSchemeId),callback:function ($$v) {_vm.selectedSchemeId=$$v},expression:"selectedSchemeId"}})],1)],1),(_vm.scoreSchemeData)?_c('v-data-table',{attrs:{"headers":[
          { value: 'index', text: 'No' },
          { value: 'GRADE_ID', text: 'GRADE_ID' },
          { value: 'MIN_GRADE_MARK', text: 'MIN_GRADE_MARK' },

          { value: 'MAX_GRADE_MARK', text: 'MAX_GRADE_MARK' },
          { value: 'GRADE_CODE', text: 'GRADE_CODE' },
          { value: 'GRADE_POINTS', text: 'GRADE_POINTS' },
          { value: 'GRADE_OUTCOME', text: 'GRADE_OUTCOME' },

          { value: 'TOTAL_MIN', text: 'TOTAL_MIN' },
          { value: 'TOTAL_MAX', text: 'TOTAL_MAX' },
          { value: 'COLOR_CODE', text: 'COLOR_CODE' },
          { value: 'SEQUENCE', text: 'SEQUENCE' },
        ],"items":_vm.scoreSchemeData,"item-value":"id","dense":"","search":_vm.search,"hide-default-footer":"","items-per-page":-1}}):_vm._e()],1),_c('v-tab-item',{staticClass:"px-4"},[_c('h2',{staticClass:"mt-4 text-typo"},[_vm._v("Ээлжит xичээлийн жагсаалт")]),(_vm.loading)?_c('v-progress-linear',{attrs:{"color":"red","height":"6","indeterminate":""}}):_vm._e(),_c('v-row',{staticClass:"py-4"},[_c('v-col',[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Xайx","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),(_vm.esisSavedLessons)?_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.esisSavedLessons,"item-value":"id","dense":"","search":_vm.search,"hide-default-footer":"","items-per-page":-1}}):_vm._e()],1),_c('v-tab-item',[_c('v-card',{staticClass:"pa-16"},[_c('v-text-field',{model:{value:(_vm.username),callback:function ($$v) {_vm.username=$$v},expression:"username"}}),_c('v-text-field',{model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('v-btn',{on:{"click":_vm.loginToEsisTeacher}},[_vm._v("Login")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }