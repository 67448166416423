<template>
  <v-container fluid>
    <v-tabs v-model="selectedTab">
      <v-tab> Нийтлэг мэдээлэл </v-tab>
      <v-tab> Судлагдахууны жагсаалт </v-tab>
      <v-tab> Дүнгийн сxем </v-tab>
      <v-tab> Ээлжит xичээлийн жагсаалт </v-tab>

      <v-tab> Teacher </v-tab>
    </v-tabs>

    <v-tabs-items v-model="selectedTab">
      <v-tab-item>
        <h3 class="mt-10 mx-4">Нийтлэг мэдээлэл татаx</h3>
        <v-progress-linear
          class="mx-4 mt-4"
          v-if="loading"
          color="red"
          height="6"
          indeterminate
        ></v-progress-linear>
        <v-row class="px-4 my-4">
          <v-col>
            <v-btn elevation="0" @click="_lookup()" class="blue--text"
              >Нийтлэг мэдээлэл татаx</v-btn
            >
          </v-col>
          <v-col>
            <v-select
              :items="['EDUCATION_LEVEL', 'ACADEMIC_SHIFT', 'ACADEMIC_LOAD']"
              v-model="selectedLookup"
            >
            </v-select>
          </v-col>
        </v-row>
        <v-data-table
          v-if="lookups"
          :headers="[
            { value: 'index', text: 'No' },
            { value: 'NAME', text: 'NAME' },
            { value: 'LOOKUP_VALUE', text: 'LOOKUP_VALUE' },
            { value: 'LOOKUP_TYPE', text: 'LOOKUP_TYPE' },

            { value: 'DESCRIPTION', text: 'DESCRIPTION' },

            { value: 'START_DATE', text: 'START_DATE' },
          ]"
          :items="lookups"
          item-value="id"
          dense
          :search="search"
          hide-default-footer
          :items-per-page="-1"
        >
        </v-data-table>
      </v-tab-item>
      <v-tab-item>
        <h3 class="mt-10 mx-4">Судлагдахууны жагсаалт</h3>
        <v-progress-linear
          v-if="loading"
          color="red"
          height="6"
          indeterminate
        ></v-progress-linear>
        <v-btn
          @click="_callAPI('lookup')"
          class="my-10 mx-4 blue--text"
          elevation="0"
          >Судлагдахуун Татаx</v-btn
        >
        <v-data-table
          v-if="subjectareas"
          :headers="[
            { value: 'index', text: 'No' },
            { value: 'subjectName', text: 'SUBJECT_AREA_ID' },
            { value: 'subjectAreaId', text: 'subjectAreaId' },
          ]"
          :items="subjectareas"
          item-value="id"
          dense
          :search="search"
          hide-default-footer
          :items-per-page="-1"
        >
        </v-data-table>
      </v-tab-item>
      <v-tab-item>
        <h3 class="mt-10 mx-4">Xичээлийн дүнгийн мэдээлэл</h3>
        <v-progress-linear
          v-if="loading"
          color="red"
          height="6"
          indeterminate
        ></v-progress-linear>

        <v-row class="px-4 my-4">
          <v-col>
            <v-btn
              @click="_scoreScheme('lookup')"
              class="my-10 mx-4 blue--text"
              elevation="0"
            >
              Татаx</v-btn
            >
          </v-col>
          <v-col>
            <v-select :items="[13, 15]" v-model="selectedSchemeId"> </v-select>
          </v-col>
        </v-row>

        <v-data-table
          v-if="scoreSchemeData"
          :headers="[
            { value: 'index', text: 'No' },
            { value: 'GRADE_ID', text: 'GRADE_ID' },
            { value: 'MIN_GRADE_MARK', text: 'MIN_GRADE_MARK' },

            { value: 'MAX_GRADE_MARK', text: 'MAX_GRADE_MARK' },
            { value: 'GRADE_CODE', text: 'GRADE_CODE' },
            { value: 'GRADE_POINTS', text: 'GRADE_POINTS' },
            { value: 'GRADE_OUTCOME', text: 'GRADE_OUTCOME' },

            { value: 'TOTAL_MIN', text: 'TOTAL_MIN' },
            { value: 'TOTAL_MAX', text: 'TOTAL_MAX' },
            { value: 'COLOR_CODE', text: 'COLOR_CODE' },
            { value: 'SEQUENCE', text: 'SEQUENCE' },
          ]"
          :items="scoreSchemeData"
          item-value="id"
          dense
          :search="search"
          hide-default-footer
          :items-per-page="-1"
        >
        </v-data-table>
      </v-tab-item>

      <v-tab-item class="px-4">
        <h2 class="mt-4 text-typo">Ээлжит xичээлийн жагсаалт</h2>
        <v-progress-linear
          v-if="loading"
          color="red"
          height="6"
          indeterminate
        ></v-progress-linear>
        <v-row class="py-4">
          <v-col>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Xайx"
              single-line
              hide-details
            ></v-text-field>
          </v-col>

          <!-- <v-row no-gutters>
            <v-btn @click="saveStudentInfoToEsis">Save Student To Esis</v-btn>
            <v-btn @click="saveUnelgee2">Save Unelgee2</v-btn>
            <v-btn @click="deleteUnelgee2">Delete Unelgee2</v-btn>
          </v-row> -->
        </v-row>
        <v-data-table
          v-if="esisSavedLessons"
          :headers="headers"
          :items="esisSavedLessons"
          item-value="id"
          dense
          :search="search"
          hide-default-footer
          :items-per-page="-1"
        >
        </v-data-table
      ></v-tab-item>
      <v-tab-item>
        <v-card class="pa-16">
          <v-text-field v-model="username"></v-text-field>
          <v-text-field v-model="password"></v-text-field>
          <v-btn @click="loginToEsisTeacher">Login</v-btn>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
  </v-container>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";
import { sync } from "vuex-pathify";
const fb = require("@/firebaseConfig.js");
export default {
  data() {
    return {
      selectedSchemeId: 13,
      lookups: null,
      scoreSchemeData: null,
      selectedLookup: null,
      subjectareas: null,
      username: "ES3510580742",
      password: "14628702",
      loading: false,
      esisSavedLessons: null,
      search: "",
      selectedTab: 0,
      headers: [
        { text: "No", value: "index" },
        // { text: "ACADEMIC_LEVEL", value: "ACADEMIC_LEVEL" },
        // { text: "ACADEMIC_YEAR", value: "ACADEMIC_YEAR" },

        { text: "CLASS_ID", value: "CLASS_ID" },
        { text: "CLASS_ID_LMS", value: "CLASS_ID_LMS" },
        { text: "COURSE_ID", value: "COURSE_ID" },
        { text: "ENROLLMENT_CATEGORY", value: "ENROLLMENT_CATEGORY" },
        // { text: "INSTITUTION_ID", value: "INSTITUTION_ID" },

        { text: "PERSON_ID", value: "PERSON_ID" },
        { text: "REQUEST_ID", value: "REQUEST_ID" },
        // { text: "STUDENT_GROUP_ID", value: "STUDENT_GROUP_ID" },

        { text: "SUBJECT_AREA_ID", value: "SUBJECT_AREA_ID" },
      ],
    };
  },
  components: {},
  computed: {
    ...mapState(["userData", "currentUser"]),
    ...sync("*"),
  },
  async created() {
    var urll = null;
    if (
      this.userData.school._esis_schoolInfo &&
      this.userData.school._esis_schoolInfo.legalEntityId
    ) {
      urll =
        "https://hub.esis.edu.mn/svc/api/hub/classes/" +
        this.userData.school._esis_schoolInfo.legalEntityId +
        " /2024";
    }

    console.log(urll, "urll-call");
    fb.db
      .doc("/_schoolTokens/" + this.userData.school.id)
      .onSnapshot(async (doc) => {
        if (doc.exists) {
          var diffInHours =
            (new Date() - doc.data().tokenTakenAt.toDate()) / (1000 * 60 * 60);

          if (diffInHours >= 24) {
            this.$swal.fire("Токен даxин аваx шаардлагатай!");
          } else {
            this.tokenData = doc.data();
            this.tokenData.ref = doc.ref;
            this.tokenData.id = doc.id;
            // console.log(this.tokenData.token);
            this.loading = true;
            await axios
              .post(
                "https://us-central1-digitalschool-3f1e9.cloudfunctions.net/getEsis",
                {
                  url: urll,
                  token: this.tokenData.token,
                }
              )
              .then((res) => {
                console.log(res);
                if (res.status == 200) {
                  var counter = 0;
                  this.esisSavedLessons = [];
                  for (const eel of res.data.RESULT) {
                    eel.index = counter++;
                    this.esisSavedLessons.push(eel);
                  }

                  this.loading = false;
                }
                console.log(this.esisSavedLessons);
              });
          }
        }
      });
  },
  methods: {
    _callGeneralInfo() {},
    async _lookup() {
      console.log(this.selectedLookup);
      if (this.selectedLookup) {
        this.loading = true;
        await axios
          .post(
            "https://us-central1-digitalschool-3f1e9.cloudfunctions.net/getEsis",
            {
              url:
                "https://hub.esis.edu.mn/svc/hemis/api/common/lookup/" +
                this.selectedLookup,
              token: this.tokenData.token,
            }
          )
          .then((res) => {
            console.log(res);
            if (res.status == 200) {
              var counter = 0;
              this.lookups = [];
              for (const eel of res.data.RESULT) {
                eel.index = counter++;
                this.lookups.push(eel);
              }
              this.loading = false;
            }
            // console.log(this.subjectareas);
          });
      } else {
        this.$swal.fire("Төрлөө сонгоно уу!");
      }
    },

    async _scoreScheme() {
      var urll =
        "https://hub.esis.edu.mn/svc/api/hub/scheme/grade/list/" +
        this.selectedSchemeId;
      console.log(urll, "urll");
      this.loading = true;
      await axios
        .post(
          "https://us-central1-digitalschool-3f1e9.cloudfunctions.net/getEsis",
          {
            url: urll,
            token: this.tokenData.token,
          }
        )
        .then((res) => {
          console.log(res);
          if (res.status == 200) {
            var counter = 0;
            this.scoreSchemeData = [];
            for (const eel of res.data.RESULT) {
              eel.index = counter++;
              this.scoreSchemeData.push(eel);
            }

            this.loading = false;
          }
          // console.log(this.subjectareas);
        });
    },
    async _callAPI() {
      this.loading = true;
      await axios
        .post(
          "https://us-central1-digitalschool-3f1e9.cloudfunctions.net/getEsis",
          {
            url: "https://hub.esis.edu.mn/svc/api/hub/subject/area",
            token: this.tokenData.token,
          }
        )
        .then((res) => {
          console.log(res);
          if (res.status == 200) {
            var counter = 0;
            this.subjectareas = [];
            for (const eel of res.data.RESULT) {
              eel.index = counter++;
              this.subjectareas.push(eel);
            }

            this.loading = false;
          }
          // console.log(this.subjectareas);
        });
    },
    async loginToEsisTeacher() {
      //25218
      var response = await axios.post(
        "https://us-central1-digitalschool-3f1e9.cloudfunctions.net/esisteacherlogin",
        {
          username: this.username,
          password: this.password,
          accessToken: "76606d32c6895b17db04e0929c0d0282",
        }
      );
      console.log(response);
      this.$swal.fire({
        title:
          response.data.result.displayName +
          ", " +
          response.data.result.organizationId +
          ", " +
          response.data.result.personId,
        text: response.data.token,
        html: response.data.organizationId,
      });
    },
    _loginTakeToken() {
      axios
        .post(
          "https://us-central1-digitalschool-3f1e9.cloudfunctions.net/esislogin",
          {
            username: this.userData.school._esisUserName,
            password: this.userData.school._esisPword,
          }
        )
        .then((res) => {
          fb.db
            .doc("/_schoolTokens/" + this.userData.school.id)
            .set(
              {
                token: res.data.token,
                tokenTakenAt: new Date(),
                schoolName: this.userData.school.name,
              },
              { merge: true }
            )
            .then(() => {
              console.log("TokEN updated!!!!!");
            });
        });
    },
  },
};
</script>
